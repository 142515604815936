<div class="emd-container eu-display_flex eu-column no-border">
    <div class="emd-container">
        <div class="emd-container__header title">
            <h2 class="emd-text _size_page _align_center w-100">{{ confirmationTitle }}</h2>
        </div>
        <div class="emd-container__content eu_">
            <div class="eu-display_flex eu-justify_center text_color eu-mx_5">
                {{ confirmationText }}
            </div>
        </div>
    </div>
    <div class="emd-container__footer eu-display_flex eu-justify_center">
        <button class="emd-btn not_confirm_color no-border" (click)="setConfirmationAsFalse()">
            {{ notConfirmButtonText }}
        </button>
        <button class="emd-btn confirm_color no-border" (click)="setConfirmationAsTrue()">
            {{ confirmButtonText }}
        </button>
    </div>
</div>
