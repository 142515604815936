import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModal {
    constructor(private activeModal: NgbActiveModal) {}

    confirmationTitle = 'Confirme a operação para prosseguir'
    confirmationText = ''
    confirmButtonText = 'Sim'
    notConfirmButtonText = 'Não'

    setConfirmationAsTrue() {
        this.activeModal.close({
            confirmation: true,
        })
    }

    setConfirmationAsFalse() {
        this.activeModal.close({
            confirmation: false,
        })
    }
}
